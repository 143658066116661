import { Injectable } from '@angular/core';
import { HttpClient , HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers = new HttpHeaders()
      .set('Content-type' , 'application/json; charset=utf-8')
      .set( 'Accept', 'text/plain, */*',)

  private apiUrl : string = environment.API_URL

  constructor(
      private http : HttpClient,
      private router : Router
    ) { }


    public get(param : string)
    {
      return this.http.get<any>(this.apiUrl + param , {
        headers : this.headers,
        responseType : 'json'
      })
    }

    public post(param : string , body : any)
    {
      return this.http.post<any>(this.apiUrl + param , (body))
    }

    public patch(param : string , body : any)
    {
      return this.http.patch(param , body)
    }
    public putNoBody(param : string)
    {
      return this.http.put(this.apiUrl + param,null)
    }

    public delete(param : string)
    {
      return this.http.delete(this.apiUrl + param)
    }

    erorHandler(error : HttpErrorResponse )
    {
        return throwError(error.message || 'Data Not Found' );
    }

    getProjects() : Observable<any>
    {
      return this.get('Projects') as Observable<any>
    }


}
