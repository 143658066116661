import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-table',
  templateUrl: './global-table.component.html',
  styleUrls: ['./global-table.component.scss']
})
export class GlobalTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
