import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profitability',
  templateUrl: './profitability.component.html',
  styleUrls: ['./profitability.component.scss']
})
export class ProfitabilityComponent implements OnInit , OnChanges {

  @Input() data : any;
  @Input() isError! : boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']){
      this.data = this.data
    }
    if(changes['isError']) {
      this.isError = this.isError
    }
  }

  ngOnInit(): void {
  }

}
