import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from 'src/app/global-component/dialog-error/dialog-error.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private isOpen : boolean = false;
  private errorCount = 0

  constructor(private router : Router , 
              private activatedRoute : ActivatedRoute ,
              private dialog : MatDialog ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request)
           .pipe(
              // map((event : HttpEvent<any>) => {
              //   if(event instanceof HttpResponse) {
              //     if(event.status === 204){
              //       this.router.navigate(['/not-found'])
              //     }
              //   }
              //   return event
              // }),
              shareReplay(1),
              catchError((error : HttpErrorResponse) => {
                
                if(error.status === 401 ){
                  this.isOpen = true
                  this.errorCount = this.errorCount + 1

                  this.router.events.forEach((event) => {
                    
                    if (event instanceof NavigationStart){

                        this.activatedRoute.queryParams.subscribe(
                          (res : any) => {
                            if(res.token || event['url'] == '/login' || event['url'] == '/' || event['url'] == '/forgot-password' ) {
                              return
                            }else {
                              this.router.navigate(['/login'])
                            }
                          }
                        )
              
                    }
              
                    if (event instanceof NavigationEnd){

                      this.activatedRoute.queryParams.subscribe(
                        (res : any) => {
                          if(res.token || event['url'] == '/login' || event['url'] == '/' || event['url'] == '/forgot-password' ) {
                            return
                          }else {
                            this.router.navigate(['/login'])
                          }
                        }
                      )
            
                  }

                  })
                  if(this.errorCount === 1 ) {

                    this.dialog.open(DialogErrorComponent).afterClosed().subscribe(
                      (res : any) => {
                        this.router.navigate(['/login'])
                        this.errorCount = 0;
                      } 
                    )

                  }
                }
                // else {
                //   this.dialog.open(DialogErrorComponent).afterClosed().subscribe(
                //     (res : any) => {
                //       this.router.navigate(['/login'])
                //     } 
                //   )
                // }
                // console.log(error)
                return throwError(error);
              })
           );
  }
}
