<section class="header-comp">
  <section class="top">
    <mat-icon class="sort-icc mr-4" (click)="toggleSideBar()">sort</mat-icon>

    <h1>ERP Dashboard</h1>

    <div class="burger ml-auto" (click)="toggleSidebarMobile()">
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div class="right flex justify-end ml-auto">
      <div class="message-push-notif" style="display: {{pushShow}};">
        <h5>{{tooltipTitle}}</h5>
       {{tooltipContent}}
      </div>

      <span
        matBadgeSize="small"
        [matBadge]="countUnread == '0'? '':countUnread"
        matBadgePosition="after"
        class="custom-badge-accent mr-5 mt-1"
        matBadgeColor="accent">
        <mat-icon class="text-white"
                  (click)="isShow = !isShow"
                  cdkOverlayOrigin #trigger="cdkOverlayOrigin">notifications</mat-icon>
      </span>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isShow"
      >
        <cdk-accordion class="example-accordion"
                       (mouseleave)="onMouseLeave()">
          <cdk-accordion-item
            *ngFor="let item of listNotification; let index = index;"
            #accordionItem="cdkAccordionItem"
            class="example-accordion-item"
            role="button"
            [tabindex]="index"
            [attr.id]="'accordion-header-' + index"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + index">
            <div class="example-accordion-item-header {{item.isRead ? '':'font-bold'}}" (click)="readMessage(item.id,index,accordionItem.toggle())">
              {{ item.title }}
              <span class="example-accordion-item-description {{item.isRead ? '':'font-bold'}}">
        Click to open
      </span>
            </div>
            <div
              class="example-accordion-item-body"
              role="region"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-body-' + index"
              [attr.aria-labelledby]="'accordion-header-' + index">
              {{item.message}}
            </div>
          </cdk-accordion-item>
        </cdk-accordion>
      </ng-template>

      <div class="profile" (click)="isProfile = !isProfile">
        <span class="profile-pict">
          {{ userName }}
        </span>

        <div class="profile-info shadow" [ngClass]="{ openProfile: isProfile }">
          <span class="logout">
            <span class="generate">
              <a [routerLink]="['/', 'generate']">Generate</a>
            </span>
            <span (click)="logout()"> Logout </span>
            <span class="trianggle"></span>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section class="bottom">
    <!-- <h1>
            Homepage
        </h1> -->

    <app-bread-crumb [data]="dataBreadcrumb"></app-bread-crumb>

    <div class="right sm:hidden md:hidden" *ngIf="!isHide">
      <!-- projects -->
      <div class="project mr-4" *ngIf="filterId != 3">
        <mat-form-field>
          <mat-select
            (selectionChange)="getChangeDate()"
            name="countryVaraible"
            [(value)]="project"
            placeholder="Project"
          >
            <mat-option *ngFor="let item of projectData" [value]="item?.id">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- location -->
      <div class="level mr-4" *ngIf="filterId == 2">
        <mat-form-field>
          <mat-select
            (selectionChange)="paramApiService.location.next(selectedLocation)"
            name="countryVaraible"
            [(value)]="selectedLocation"
            placeholder="Location"
          >
            <mat-option *ngFor="let item of listLocation" [value]="item?.id">
              {{ item?.nama }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- level -->
      <div class="level mr-4" *ngIf="filterId == 2">
        <mat-form-field>
          <mat-select
            (selectionChange)="paramApiService.level.next(selectedLevel)"
            name="countryVaraible"
            [(value)]="selectedLevel"
            placeholder="Level"
          >
            <mat-option *ngFor="let item of level" [value]="item?.value">
              {{ item?.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="calendar" *ngIf="filterId != 3">
        <mat-form-field appMonthYearDatePickerFormat>
          <!-- <mat-label>Month and Year</mat-label> -->
          <input
            matInput
            [matDatepicker]="picker"
            [formControl]="date2"
            placeholder="Month and Year"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="getChangeDate()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, picker)"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <!-- filter projects cash In -->
      <div class="project mr-4" *ngIf="filterId == 3">
        <mat-form-field>
          <mat-select
            (selectionChange)="onSelectProject()"
            name="countryVaraible"
            [(value)]="multipleProject"
            placeholder="Project"
            multiple
          >
            <mat-option *ngFor="let item of projectData" [value]="item?.id">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- filter calendar cash In -->
      <div class="calendar" *ngIf="filterId == 3">
        <mat-form-field appFullDatePickerFormat>
          <mat-label>Dari</mat-label>
          <input
            matInput
            [matDatepicker]="pickerFrom"
            [formControl]="fromDate"
            placeholder="Dari"
            (dateChange)="onChangeFromDate()"
            [max]="maxDateNow"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerFrom
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
        <span class="ml-2 mr-2">-</span>
        <mat-form-field appFullDatePickerFormat>
          <mat-label>Sampai</mat-label>
          <input
            matInput
            [matDatepicker]="pickerEnd"
            [formControl]="endDate"
            placeholder="Sampai"
            (dateChange)="onChangeEndDate()"
            [max]="maxDateNow"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerEnd
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </section>
</section>
