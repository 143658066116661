import { Component, OnInit , Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-tooltip',
  templateUrl: './dialog-tooltip.component.html',
  styleUrls: ['./dialog-tooltip.component.scss']
})
export class DialogTooltipComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogTooltipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    // console.log('data from dialog' , this.data)
  }

  onClose()
  {
    this.dialogRef.close()
  }

}
