import { Injectable } from '@angular/core';
import { HttpClient , HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Api2Service {

  private headers = new HttpHeaders()
  .set('Content-type' , 'application/json; charset=utf-8')
  .set( 'Accept', 'text/plain, */*',)

  private apiUrl2 : string = environment.API_URL2

  constructor( private http : HttpClient,
    private router : Router) { }

  
  public get(param : string)
  {
    return this.http.get<any>(this.apiUrl2 + param , {
      headers : this.headers,
      responseType : 'json'
    })
  }

  public post(param : string , body : any)
  {
    return this.http.post<any>(this.apiUrl2 + param , (body))
  }

  public patch(param : string , body : any)
  {
    return this.http.patch(param , body)
  }

  public delete(param : string)
  {
    return this.http.delete(this.apiUrl2 + param)
  }

  erorHandler(error : HttpErrorResponse )
  {
      return throwError(error.message || 'Data Not Found' );
  }

}
