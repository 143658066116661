import { Injectable } from '@angular/core';
import { Observable , BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isLogin : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  token : BehaviorSubject<any> = new BehaviorSubject<any>('')
  userName : BehaviorSubject<any> = new BehaviorSubject<any>('')
  
  constructor() { }
}
