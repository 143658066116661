import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbModel } from 'src/app/models/breadcrumb/breadcrum.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumService {

  public data : BehaviorSubject<BreadcrumbModel> = new BehaviorSubject<BreadcrumbModel>({parentPage : '' , page : 'homepage'})

  constructor() { }
}
