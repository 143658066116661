import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApexLegend, ApexResponsive, ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  colors: string[];
  legend: ApexLegend;
  responsive: ApexResponsive | ApexResponsive[];
  plotOptions: ApexPlotOptions;
  states : any;
};

@Component({
  selector: 'app-card-margin',
  templateUrl: './card-margin.component.html',
  styleUrls: ['./card-margin.component.scss']
})
export class CardMarginComponent implements OnInit , OnChanges {
  // add comment here here
  @Input() data : any;
  @Input() label : any;
  @Input() label2? : any;
  @Input() offsetX? : any;
  @Input() offsetY? : any;
  @Input() shadow? : boolean; 
  @Input() isError! : boolean;
  

  @ViewChild("chart") chart!: ChartComponent | any;
  public title : any = 'Gross Profit Margin (GPM %)';
  public chartOptions!: Partial<ChartOptions> | any;

  constructor() {
    this.chartOptions = {
      // series: [90, 55],
      colors: ["#D4861F" , "#BD0001"],
      chart: {
        height: 350,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "35%",
            background: "transparent",
            image: undefined
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            }
          }
        }
      },
      labels: ["Actual", "Plan"],
      legend: {
        show: true,
        floating: true,
        fontSize: "18px",
        position: "left",
        offsetX: 5,
        offsetY: 25,
        markers : {size : 0},
        labels: {
          useSeriesColors: true,
          markers : {size : 0}
        },
        formatter: function(seriesName : any, opts : any) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          // return seriesName;
        },
        itemMargin: {
          horizontal: 3
        }
      },
      markers : {
        size : 0,
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 0,
            size: 0
          },
          {
            seriesIndex: 0,
            dataPointIndex: 1,
            fillColor: '#419EF7',
            strokeColor: '#fff',
            size: 0
          }
        ]
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: true
            }
          }
        },
        {
          breakpoint: 1366,
          options: {
            legend: {
              show: true,
              fontSize: "15px",
              offsetX: 15,
            }
          }
        }
      ],
      states : {
        hover: {
          filter: {
              type: 'lighten',
              value: 0.15,
          }
        },
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] || changes['shadow']){
      this.chartOptions = {
        ...this.chartOptions,
        series : [this.data?.data?.actual , this.data?.data?.plan],
        legend : {
          ...this.chartOptions.legend,
          offsetX : this.data?.offsetX,
          fontSize : this.data?.fontSize

        }
      }
    }

    if(changes['isError']) {
      this.isError = this.isError
    }
  }

  ngOnInit(): void {
  }

}
