<section class="card-table-comp shadow-smooth" *ngIf="data != undefined">
  <div class="bg1" [ngStyle]="{ background: backgroundColor }"></div>
  <div class="bg2" [ngStyle]="{ background: backgroundColor }"></div>

  <div class="title">
    <h2>{{ label }}</h2>
  </div>

  <div class="filter-radio" *ngIf="showFilter">
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="filtered"
      (change)="onReturnFilter(filtered)"
    >
      <mat-radio-button
        *ngFor="let item of filter"
        [value]="item?.field"
        [checked]="item?.checked"
        class="mr-2"
        >{{ item?.label | titlecase }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div class="wrapper-table">
    <!-- Actual -->
    <h1 class="actual">
      {{ data[filtered]?.actual | number }}
    </h1>

    <div class="flex flex-row justify-between">
      <h5 class="plan">{{ data[filtered]?.plan | number }}</h5>
      <h5 class="outstanding">
        <mat-icon
          class="mr-2"
          [ngClass]="
            data[filtered]?.outstanding > 0
              ? 'top-arrow'
              : data[filtered]?.outstanding < 0
              ? 'down-arrow'
              : 'none'
          "
          >arrow_downward</mat-icon
        >
        {{
          data[filtered]?.outstanding !== "NaN"
            ? data[filtered]?.outstanding == "Infinity"
              ? 0 + " %"
              : data[filtered]?.outstanding
              ? data[filtered]?.outstanding + " %"
              : ""
            : ""
        }}
      </h5>
    </div>
    <!-- Marketing Sales -->
    <!-- <table class="table-fixed" *ngIf="columns?.label == 'Marketing Sales YTD' ">
          <thead>
  
            <tr class="subheader">
              <th *ngFor="let col of columns?.data" [innerHTML]="col?.label" [ngStyle]="{ 'color' : col?.color }"> </th>
            </tr>
  
          </thead>
          <tbody>
            <tr>
              <th>Unit</th>
              <td> {{ data?.actualUnit | number : '1.2-2' }} </td>
              <td> {{ data?.actualIDR | number : '1.2-2' }} </td>
            </tr>
            <tr>
              <th>Rp</th>
              <td> {{ data?.planUnit | number : '1.2-2' }} </td>
              <td> {{ data?.planIDR | number : '1.2-2' }} </td>
            </tr>
            <tr>
              <th>Total</th>
              <td> {{  (((data?.actualUnit / data?.planUnit) - 1) * 100 ).toFixed(2) == 'NaN' ? '' : (((data?.actualUnit / data?.planUnit) - 1) * 100 ).toFixed(2) + '%' }} </td>
              <td> {{ (((data?.actualIDR / data?.planIDR) - 1) * 100).toFixed(2) == 'NaN' ? '' : (((data?.actualIDR / data?.planIDR) - 1) * 100).toFixed(2) + '%' }} </td>
            </tr>
          </tbody>
        </table>
   -->
    <!-- not Marketing Sales -->
    <!-- <table class="table-fixed" *ngIf="columns?.label != 'Marketing Sales YTD' ">
          <thead>
            <tr class="subheader">
              <th *ngFor="let col of columns?.data" [innerHTML]="col?.label" [ngStyle]="{ 'color' : col?.color }">
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Carry Over</th>
              <td>{{ data?.actualCarryOver | number : '1.2-2'  }}</td>
              <td>{{ data?.actualNew | number : '1.2-2'  }}</td>
              <td>
                {{ (data?.actualCarryOver + data?.actualNew) === 'NaN' ? '' : (data?.actualCarryOver + data?.actualNew) ? ((data?.actualCarryOver + data?.actualNew) | number : '1.2-2' ) : '' }}
                <span>
                  <app-progress-bar [color]="'red'" [height]="'5'" [width]="17" [color]="'red'"  ></app-progress-bar>
                </span>
              </td>
            </tr>
            <tr>
              <th>New</th>
              <td>{{ data?.planCarryOver | number : '1.2-2'  }}</td>
              <td>{{ data?.planNew | number : '1.2-2'  }}</td>
              <td>{{ (data?.planCarryOver + data?.planNew) === 'NaN' ? '' : (data?.planCarryOver + data?.planNew) ? ((data?.planCarryOver + data?.planNew) | number : '1.2-2' ) : ''  }}
                 <span>
                  <app-progress-bar [color]="'red'" [height]="'5'" [width]="17" [color]="'red'"  ></app-progress-bar>
                </span>
              </td>
            </tr>
            <tr>
              <th>Total</th>
  
              <td> {{  (((data?.actualCarryOver / data?.planCarryOver) - 1) * 100).toFixed(2) == 'NaN' ? ''  :  (((data?.actualCarryOver / data?.planCarryOver) - 1) * 100).toFixed(2) + '%' }} </td>
              <td> {{  (((data?.actualNew / data?.planNew) - 1) * 100).toFixed(2) == 'NaN' ? '' : (((data?.actualNew / data?.planNew) - 1) * 100).toFixed(2) + '%' }} </td>
              <td> {{  (( ((data?.actualCarryOver + data?.actualNew) / (data?.planCarryOver + data?.planNew)) - 1) * 100).toFixed(2) == 'NaN' ? '' : (( ((data?.actualCarryOver + data?.actualNew) / (data?.planCarryOver + data?.planNew)) - 1) * 100).toFixed(2) + '%' }} 
                 <span>
                  <app-progress-bar [color]="'red'" [height]="'5'" [width]="17" [color]="'blue'" ></app-progress-bar>
                </span>
              </td>
            </tr>
          </tbody>
        </table> -->
  </div>
</section>
