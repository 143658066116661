import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { ParamApiService } from 'src/app/shared/services/param-api.service';

@Component({
  selector: 'app-filter-location-level',
  templateUrl: './filter-location-level.component.html',
  styleUrls: ['./filter-location-level.component.scss']
})
export class FilterLocationLevelComponent implements OnInit {

  public selectedLocation : any = null
  public listLocation : Array<any> = []
  public selectedLevel : any = 1
  public level : Array<any> = [
    { label : '1' , value : '1' },
    { label : '2' , value : '2' },
    { label : '3' , value : '3' },
    { label : '4' , value : '4' },
  ]

  constructor(public paramApiService : ParamApiService , private headerService : HeaderService) { }

  ngOnInit(): void {

     // location
     this.paramApiService.idProject.subscribe(
      idProject => {
        this.headerService.getFilterLocation(idProject)
        .subscribe((res : Array<any>)=> {
          this.listLocation = [ { nama : 'All Location' , lokasi : null } , ...res ]
        },
        err => {
          console.log(err)
        })
      },
    )

  }

}
