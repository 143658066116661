import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
    public isOpen : BehaviorSubject<any> = new BehaviorSubject<any>(null)
    public sidebarHide : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    constructor()
    {

    }


}