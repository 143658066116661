
<section class="provitability-comp shadow-smooth">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->
  <h3 class="title-text">
    Feasibility
  </h3>
  <div class="provitability-row flex gap-4">

    <span class="wrapper flex flex-col">
      <span class="bg text-title">
        IRR
        <br>
        (Internal Rate of Return)
      </span>
      <span class="bg text-indicator">
        {{data?.irr}}
      </span>
      <span class="bg text-actual">
        {{ (data?.irrActual !== null) ? ( data?.irrActual | number : '1.2-2') : 0}}%
      </span>
      <span class="bg text-plan">
        {{ (data?.irrPlan !== null) ? ( data?.irrPlan | number : '1.2-2') : 0}}%
      </span>
    </span>
    <div class="v-line"></div>
    <span class="wrapper flex flex-col">
      <span class="bg text-title">
        NPV
        <br>
        (Net Present Value)
      </span>
      <span class="bg text-indicator">
        {{data?.npv}}
      </span>
      <span class="bg text-actual">
        {{ (data?.npvActual !== null) ? ( data?.npvActual | number : '1.2-2') : 0}}
      </span>
      <span class="bg text-plan">
        {{ (data?.npvPlan !== null) ? ( data?.npvPlan | number : '1.2-2') : 0}}
      </span>
    </span>
    <div class="v-line"></div>
    <span class="wrapper flex flex-col">
      <span class="bg text-title">
        WACC 
        <br>
        (Weighted Average Cost of Capital)
      </span>
      <span class="bg text-indicator">
       {{data?.wacc}}
      </span>
      <span class="bg text-actual">
        {{ (data?.waccActual !== null) ? ( data?.waccActual | number : '1.2-2') : 0}}%
      </span>
      <span class="bg text-plan">
        {{ (data?.waccPlan !== null) ? ( data?.waccPlan | number : '1.2-2') : 0}}%
      </span>
     
    </span>
  </div>

</section>