import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ParamApiService {

  private dateNow = new Date()
  private monthStartDay = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1); // get date 1 of month
  public date: BehaviorSubject<any> = new BehaviorSubject<any>(this.monthStartDay)
  public idProject: BehaviorSubject<any> = new BehaviorSubject<any>('ABC')
  public location: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public level: BehaviorSubject<any> = new BehaviorSubject<any>(1)
  public multipleProject: BehaviorSubject<any> = new BehaviorSubject<any>(['ABC'])
  public dateFrom: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public dateTo: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  private parameters: ParamApiModelService = {
    date: this.monthStartDay,
    idProject: 'ALL',
    multipleProject: ['ALL'],
    dateFrom: this.dateNow,
    dateTo: this.dateNow,
  }
  public parametersObs$: BehaviorSubject<ParamApiModelService> = new BehaviorSubject<ParamApiModelService>(this.parameters)

  constructor() {
    this.date.next(this.monthStartDay)
    this.idProject.next('ALL')
    this.multipleProject.next(['ALL'])
    this.dateFrom.next(this.dateNow)
    this.dateTo.next(this.dateNow)
  }

}

export interface ParamApiModelService {
  date: Date | any,
  idProject: string | any,
  location?: any,
  level?: any,
  multipleProject?: any,
  dateFrom?: any,
  dateTo?: any
}
