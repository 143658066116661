import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class CardTableComponent implements OnInit , OnChanges {

  @Output() onFiltered = new EventEmitter();
  @Input() public label : string = '';
  @Input() public columns : any;
  @Input() public data : any;
  @Input() public colorText : string = ''
  @Input() public backgroundHeader : string = ''
  @Input() public isError! : boolean;
  @Input() public backgroundColor : string = ''
  @Input() public showFilter : boolean = true;
  // @Input() public filter : Array<any> = ['carry over' , 'new' , 'total']
  @Input() public filter : Array<any> = [
    { value : 'carry over' , checked : true },
    { value : 'new' , checked : false },
    { value : 'total' , checked : false },
  ]

  filtered : any;


  constructor() { }

  ngOnInit(): void {
    if(this.columns?.label == 'Marketing Sales YTD'){
      // console.log('data' , this.data)
    }

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['columns'] || changes['data'] || changes['filter'] ){
      this.columns = this.columns
      this.data = this.data
    }

    if(changes['isError']) {
      this.isError = this.isError
    }
    this.backgroundColor = this.backgroundColor

    this.filter.forEach((el : any) => {
      if(el?.checked) {
        this.filtered = el?.field
      }
    })
  }

  onReturnFilter(param :any) {
    this.onFiltered.emit(param)
  }

  

}
