<section class="solvency-comp flex flex-col flex-wrap">

    <div class="flex flex-row flex-wrap">
        <div class="wrap-chaart" style="margin-right: 0px">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.iscr?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Interest Covered Ratio (ISCR)
                    </span>
                </span>
            </div>

            <div class=" solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.iscr?.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>

        </div>

        <div class="wrap-chaart ">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.maturingDebtPlusNCLLeft?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Maturing Debt + NCL
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.maturingDebtPlusNCLLeft.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>

        <div class="wrap-chaart ">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.maturingDebtPlusNCLLeftRight?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Maturing Debt + NCL
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.maturingDebtPlusNCLLeftRight.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>

        </div>

        <!-- <div class="wrap-chaart ">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 +  row?.debtCoverageCapability?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Debt Coverage Capability
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.debtCoverageCapability.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div> -->

    </div>

    <div class="flex flex-row flex-wrap">
        <div class="wrap-chaart" style="margin-right: 0px">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 +  row?.dscr?.image  + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Debt Service Coverage Ratio (DSCR)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.dscr.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
       
        <div class="wrap-chaart solvency ">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.clLeft?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Cash Loan (CL)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.clLeft.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
        
        <div class="wrap-chaart">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.clRight?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Cash Loan (CL)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.clRight.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
        
        <!-- <div class="wrap-chaart ">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.debtThatNeedsToBeRaised?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Debt That Needs to be Raised (CL Only)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.debtThatNeedsToBeRaised.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div> -->
        
    </div>

    <div class="flex flex-row flex-wrap">
        <div class="wrap-chaart " style="margin-right: 0px">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.debtEquity?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        DEBT/EQUITY
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.debtEquity.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
        
        <div class="wrap-chaart">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 + row?.nclLeft?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Non-Cash Loan (NCL)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.nclLeft.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
        
        <div class="wrap-chaart">
            <div class="flex">
                <span class="wrapper flex flex-col">
                    <img class="icon" [src]="srcIconV2 +  row?.nclRight?.image + png " alt="">
                </span>
                <span class="wrapper flex flex-col">
                    <span class="text-solvency">
                        Non-Cash Loan (NCL)
                    </span>
                </span>
            </div>

            <div class="solvency xl:w-4/12 lg:w-full md:w-full sm:w-full">
                <span class="wrapper flex flex-col">
                    <div class="text-solvency-value">
                        {{ row?.nclRight.data | number : '1.2-2'}}
                    </div>
                </span>
            </div>
        </div>
       
    </div>
</section>
