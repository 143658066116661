import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LoginService } from './shared/services/login.service';
import { SidebarService } from './shared/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'erp';
  isShowed : boolean = false;
  isHideSidebar : boolean = false;

  private event : any;

  constructor(
      private activatedRoute : ActivatedRoute,
      private router: Router,
      private loginService : LoginService,
      private sidebarService : SidebarService,
      private render : Renderer2
    )
  {
    router.events.forEach((event) => {

      // console.log('event' , event)

      this.event = event
      if (event instanceof NavigationStart){
        event['url'] == '/login' || event['url'] == '/' || event['url'] == '/forgot-password' ? 
          this.isShowed = false :
          this.isShowed = true
          
          this.activatedRoute.queryParams.subscribe(
            (res : any) => {
              if(res.token) {
                this.isShowed = false
              }
            }
          )

      }


    })

    this.activatedRoute.fragment.subscribe(
      res => {
        console.log(res)
      }
    )


    // this.loginService.isLogin.subscribe(
    //   val => {
    //     if(val == true){
    //       this.send()
    //     }
    //   }
    // )

    this.sidebarService.sidebarHide.subscribe(
      res => {
        this.isHideSidebar = res
      }
    )
  }

  send()
  {
    // this.router.navigate(['project-information'])
    this.router.navigate(['homepage'])
  }
  
}
