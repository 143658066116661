<section class="table-feasibility bg-white shadow-smooth p-4">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->
  <!-- <div *ngIf="backgroundHeader !== 'none' || 'transparent' " 
          class="absolute" 
          [ngStyle]="{'background-color' : backgroundHeader }">
  
    </div> -->
  <h2>
    {{ label }}
  </h2>
  <table class="table-fixed" *ngIf="columns?.length > 0">
    <tbody>
      <tr *ngFor="let col of columns">
        <th [ngStyle]="{ color: colorText }">
          <span
            *ngIf="col?.name == ' '"
            class="absolute"
            [ngStyle]="{ background: backgroundHeader }"
          ></span>
          {{ col?.name }}
        </th>
        <td *ngFor="let item of data; let i = index">
          <span
            *ngIf="col?.field == 'year'"
            class="absolute"
            [ngStyle]="{ background: backgroundHeader }"
          >
            {{
              i == 0
                ? "Total"
                : i == 1
                ? "Last Month"
                : i == 2
                ? "This Month"
                : "Next Month"
            }}
          </span>

          <span *ngIf="col?.field !== 'year'">
            {{
              col?.type === "string"
                ? i == 0
                  ? ""
                  : item[col?.field]
                : (item[col?.field] | number : "1.2-2")
            }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</section>
