import { Component, Input, Output, OnChanges, OnInit , SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill
} from "ng-apexcharts";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  colors : string[] | any;
};

@Component({
  selector: 'app-chart-column',
  templateUrl: './chart-column.component.html',
  styleUrls: ['./chart-column.component.scss']
})
export class ChartColumnComponent implements OnInit , OnChanges  {
  @Input() data : any;
  @Input() isError! : boolean;
  @Input() color : Array<any> = []
  @Input() isFiltered : boolean = false ;

  @Output() getFiltered = new EventEmitter()

  @ViewChild("chartColumns") chart: ChartComponent | any;

  public chartOptions: Partial<ChartOptions> | any;
  public filterData : any;
  public filter : Array<any> = [
    { name : 'Rupiah' , value : 'rp' },
    { name : 'Unit' , value : 'unit' },
  ]

  constructor() {

    this.filterData = this.filter[0].value

    this.chartOptions = {
      // series: [
      //   {
      //     name: "PRODUCT A",
      //     data: [31767, 54204, 38371]
      //   },
      //   {
      //     name: "PRODUCT B",
      //     data: [31767, 54204, 38371]
      //   }
      // ],
      chart: {
        type: "bar",
        height: 450,
        stacked: true,
        stackType: "normal"
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val: any, opts: any) {
            return  `${numberWithCommas(val)}` 
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      // xaxis: {
      //   categories: [
      //     "2011 Q1",
      //     "2011 Q2",
      //     "2011 Q3"
      //   ]
      // },
      yaxis: {
        // min: 0,
        // max: 60000,
        tickAmount: 2,
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "bottom",
        offsetX: 0,
        offsetY: 0
      }
    };


  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['color'] || changes['data']){
      this.chartOptions = {
        ...this.chartOptions,
        colors : this.color,
        series : this.data?.series,
        xaxis : this.data?.xaxis
      }

      // console.log('data' , this.data)
    }

    if(changes['isFiltered']) {
      this.isFiltered = this.isFiltered
      if(this.filterData == 'unit'){
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
                return  `${numberWithCommas(val)}` 
            },
          },
        }
      }

      if(this.filterData == 'rp'){
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
                return  `Rp ${numberWithCommas(val)}` 
            },
          },
        }
      }
    }

    if(changes['isError']){
      this.isError = this.isError
    }
  }

  
  ngOnInit(): void {
    this.filterData = this.filter[0].value
  }

  getChange()
  {
    if(this.isFiltered){

      if(this.filterData == 'unit'){
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
                return  `${numberWithCommas(val)}` 
            },
          },
        }
      }else {
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
                return  `Rp ${numberWithCommas(val)}` 
            },
          },
        }
      }

      this.getFiltered.emit(this.filterData)
    }
  }

}

function numberWithCommas(val: any) {
  return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}
