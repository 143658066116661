import {AfterViewInit, Component, ViewChild , OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit , AfterViewInit  {

  displayedColumns: string[] = [
    'level_rab', 
    'tipe_hpp', 
    'kategori_budget', 
    'total_budget',
    'realisasi_alokasi',
    'realisasi_mutasi',
    'deviasi',
    'realisasi_total_aksi',
    'sisa_alokasi',
    'realisasi_biaya',
    'sisa_budget',
  ];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  columns : Array<any> = [
    { name  : 'Level RAB' , field : 'level_rab' },
    { name  : 'Tipe HPP' , field : 'tipe_hpp' },
    { name  : 'Kategori Budget' , field : 'kategori_budget' },
    { name  : 'Total Nilai Budget' , field : 'total_budget' },
    { name  : 'Realisasi Alokasi Bulan ini' , field : 'realisasi_alokasi' },
    { name  : 'Realisasi Mutasi Alokasi Bulan ini' , field : 'realisasi_mutasi' },
    { name  : 'Deviasi' , field : 'deviasi' },
    { name  : 'Realisasi Total Aksi' , field : 'realisasi_total_aksi' },
    { name  : 'Sisa Alokasi' , field : 'sisa_alokasi' },
    { name  : 'Realisasi Biaya' , field : 'realisasi_biaya' },
    { name  : 'Sisa Budget' , field : 'sisa_budget' },
  ]

  data : Array<any> = [
    {
      level_rab : '4',
      tipe_hpp : 'Tanah',
      kategori_budget : 'Tanah Cluster',
      total_budget : '8130418480',
      realisasi_alokasi : '0',
      realisasi_mutasi : '0',
      deviasi : '0',
      realisasi_total_aksi : {
        value : '0',
        percent : '0'
      },
      sisa_alokasi : {
        value : '81130416480',
        percent : '0' 
      },
      realisasi_biaya : { 
        value : '81130416480',
        percent : '0' },
      sisa_budget : { value : '0' , percent : '0' }
    },
    {
      level_rab : '4',
      tipe_hpp : 'Tanah',
      kategori_budget : 'Tanah Cluster',
      total_budget : '8130418480',
      realisasi_alokasi : '0',
      realisasi_mutasi : '0',
      deviasi : '0',
      realisasi_total_aksi : {
        value : '0',
        percent : '0'
      },
      sisa_alokasi : {
        value : '81130416480',
        percent : '0' 
      },
      realisasi_biaya : { 
        value : '81130416480',
        percent : '0' },
      sisa_budget : { value : '0' , percent : '0' }
    },
    {
      level_rab : '4',
      tipe_hpp : 'Tanah',
      kategori_budget : 'Tanah Cluster',
      total_budget : '8130418480',
      realisasi_alokasi : '0',
      realisasi_mutasi : '0',
      deviasi : '0',
      realisasi_total_aksi : {
        value : '0',
        percent : '0'
      },
      sisa_alokasi : {
        value : '81130416480',
        percent : '0' 
      },
      realisasi_biaya : { 
        value : '81130416480',
        percent : '0' },
      sisa_budget : { value : '0' , percent : '0' }
    },
  ]

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.data)
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];
