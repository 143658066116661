import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-solvency',
  templateUrl: './solvency.component.html',
  styleUrls: ['./solvency.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SolvencyComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() isError!: boolean;
  public row: any;

  public srcIconV2: string = 'assets/image/icon_v2/';
  public png: string = '.png';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.data) {
        this.data = {
          iscr: this.data.reduce((current: any, obj: any) => current + obj?.iscr, 0),
          dscr: this.data.reduce((current: any, obj: any) => current + obj?.dscr, 0),
          debtEquity: this.data.reduce((current: any, obj: any) => current + obj?.debtEquity, 0),
          maturingDebtPlusNCLLeft: this.data.reduce((current: any, obj: any) => current + obj?.maturingDebtPlusNCLLeft, 0),
          maturingDebtPlusNCLLeftRight: this.data.reduce((current: any, obj: any) => current + obj?.maturingDebtPlusNCLLeftRight, 0),
          clLeft: this.data.reduce((current: any, obj: any) => current + obj?.clLeft, 0),
          nclLeft: this.data.reduce((current: any, obj: any) => current + obj?.nclLeft, 0),
          clRight: this.data.reduce((current: any, obj: any) => current + obj?.clRight, 0),
          nclRight: this.data.reduce((current: any, obj: any) => current + obj?.nclRight, 0),
          debtCoverageCapability: this.data.reduce((current: any, obj: any) => current + obj?.debtCoverageCapability, 0),
          debtThatNeedsToBeRaised: this.data.reduce((current: any, obj: any) => current + obj?.debtThatNeedsToBeRaised, 0),
        }

        this.appendIcon(this.data);
      }else{
        this.appendIcon(this.data);
      }
    }

    if (changes['isError']) {
      this.isError = this.isError
    }
  }

  appendIcon(data: any) {
    this.row = {
      iscr: {
        data: data?.iscr?? 0,
        image: "stats"
      },
      dscr: {
        data: data?.dscr?? 0,
        image: "stats"
      },
      debtEquity: {
        data: data?.debtEquity?? 0,
        image: "rupiah_yellow"
      },
      maturingDebtPlusNCLLeft: {
        data: data?.maturingDebtPlusNCLLeft?? 0,
        image: "calendar_red"
      },
      maturingDebtPlusNCLLeftRight: {
        data: data?.maturingDebtPlusNCLLeftRight?? 0,
        image: "calendar_blue"
      },
      clLeft: {
        data: data?.clLeft?? 0,
        image: "rupiah_red"
      },
      nclLeft: {
        data: data?.nclLeft?? 0,
        image: "document_red"
      },
      clRight: {
        data: data?.clRight?? 0,
        image: "rupiah_blue"
      },
      nclRight: {
        data: data?.nclRight?? 0,
        image: "document_blue"
      },
      debtCoverageCapability: {
        data: data?.debtCoverageCapability?? 0,
        image: "rupiah_green"
      },
      debtThatNeedsToBeRaised: {
        data: data?.debtThatNeedsToBeRaised?? 0,
        image: "rupiah_green"
      }
    }

    // console.log(this.row)
  }

  ngOnInit(): void {
  }


}
