<section class="primeng-table">
  <!-- <h3 class="title">
        {{ title }}
    </h3> -->

  <p-table
    #dt2
    [value]="dataTable"
    (onLazyLoad)="onLoadData($event)"
    [lazy]="true"
    [paginator]="paginator"
    [rows]="rows"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [loading]="isLoading"
    [totalRecords]="totalRecords"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[50, 100, 500, 1000, 2000, 5000]"
    [paginatorPosition]="'bottom'"
    [globalFilterFields]="globalFilters"
    [title]="title"
    scrollHeight="400px"
    [frozenColumns]="frozencols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-col">
        <h3 class="title mr-auto">
          {{ title }}
        </h3>

        <span class="p-input-icon-left ml-auto" *ngIf="isSearch">
          <span class="search-text mr-2"> Search </span>
          <input
            pInputText
            type="text"
            (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of frozencols" [innerHTML]="col.name" style="position: sticky;left: 0;top: 0;  background-color: #FBDEAA !important;  font-weight: bold; z-index: 1;">
            </th>
            <th *ngFor="let col1 of Getgabunganarray()" [innerHtml]="col1.name" style="position: sticky; top: 0; background-color: #FBDEAA !important;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr>
        <td *ngFor="let col of frozencols" style="position:sticky; left: 0; background-color: #FBDEAA !important; font-weight: bold;">{{element[col.field]}}</td>
        <td *ngFor="let col1 of Getgabunganarray()">
          <ng-container *ngIf="col1?.isPercent; else other">
            <span class="header">
              {{ element[col1.field.value1] | number }}
            </span>
            <br />
            <span
              class="sub"
              [ngClass]="{
                red: element[col1.field.value2] > 100,
                tosca: element[col1.field.value2] < 100,
                blue: element[col1.field.value2] == 100
              }"
            >
              {{ element[col1.field.value2] + " %" }}
            </span>
          </ng-container>
          <ng-template #other>
            <span *ngIf="col1.isMoney">
              {{ element[col1.field] | number }}
            </span>
            <span *ngIf="!col1.isMoney">
              {{ element[col1.field] }}
            </span>

            <!-- <span>
              {{ element[col.field] }}
            </span> -->
          </ng-template>
        </td>     
      </tr>
    </ng-template>
  </p-table>
</section>
