
    <section class="liquidity-card">

      <!-- <app-error-load *ngIf="isError"></app-error-load> -->

        <h3 class="ml-4 mt-4 title-text">
            Liquidity
        </h3>
        <mat-tab-group mat-align-tabs="start" class="remove-border-bottom" #tabGroup (selectedTabChange)="tabChanged($event)">
          <mat-tab label="This Month" [ngClass]="{'this-month': thisMonthActive==true}">
            <span class="wrapper flex flex-col card-inline">
              <span class="bg text-title mt-10">Cash Position</span>
              <span class="bg text-actual">{{ data?.cashPositionThisMonthActual | number : '1.2-2'}}</span>
              <span class="bg text-plan">{{ data?.cashPositionThisMonthPlan | number : '1.2-2'}}</span>
              <div class="h-line"></div>
              <span class="bg text-title">Net CashFlow (NFC)</span>
              <span class="bg text-actual">{{ data?.netCashFlowThisMonthActual | number : '1.2-2'}}</span>
              <span class="bg text-plan">{{ data?.netCashFlowThisMonthPlan | number : '1.2-2'}}</span>
              <div class="h-line"></div>
              <span class="bg text-cycle">Cash Convertion Cycle</span>
              <div class="grid grid-cols-2 gap-4">
                <div class="bg text-base font-medium ml-4 mb-3">{{data?.cashConversionCycle | number}} </div>
                <div  class="bg text-base font-medium text-right mr-3 mb-3">Days</div>
              </div>
            </span>
          </mat-tab>
          <mat-tab label="Year to Date" [ngClass]="thisMonthActive ? 'mat-tab-label.mat-tab-label-active2' : 'mat-tab-label.mat-tab-label-active'">
            <span class="wrapper flex flex-col card-inline">
              <span class="bg text-title  mt-10">Cash Position</span>
              <span class="bg text-actual">{{ data?.cashPositionYearToDateActual | number : '1.2-2'}}</span>
              <span class="bg text-plan">{{ data?.cashPositionYearToDatePlan | number : '1.2-2'}}</span>
              <div class="h-line"></div>
              <span class="bg text-title">Net CashFlow (NFC)</span>
              <span class="bg text-actual">{{ data?.netCashFlowYearToDateActual | number : '1.2-2'}}</span>
              <span class="bg text-plan">{{ data?.netCashFlowYearToDatePlan | number : '1.2-2'}}</span>
              <div class="h-line"></div>
              <span class="bg text-cycle">Cash Convertion Cycle </span>
              <div class="grid grid-cols-2">
                <div class="bg text-base font-medium ml-4 mb-3">{{data?.cashConversionCycle | number}} </div>
                <div  class="bg text-base font-medium text-right mr-3 mb-3">Days</div>
              </div>
            </span>
          </mat-tab>
        </mat-tab-group>
      </section>
      

