import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/shared/services/product.service';
import { ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'app-prime-table',
  templateUrl: './prime-table.component.html',
  styleUrls: ['./prime-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeTableComponent implements OnInit , OnChanges {
  @Input() columns: Array<any> = []
  @Input() data: Array<any> = []
  @Input() title: any = 'Title'
  @Input() paginator: boolean = false
  @Input() positionSearch: string = 'default'
  @Input() frozencols: any=[];
  @Input() customColumn: any[] = [];
  @Input() isLoading : boolean = true
  @Input() onLazy : boolean = false
  @Input() totalRows : number = 0
  @Input() isSearch : boolean = true
  @Output() onLazyData = new EventEmitter()

  dataTable : Array<any> = []

  products: Product[] = [];
  first = 0;
  // rows = 3;
  rows = 50;
  totalRecords : number = this.data.length ? this.data.length : 0 
  globalFilters : Array<any> = []
  textFiltered : string | any = ''


  constructor(private productService: ProductService) { }

  ngOnInit(): void {

    // this.productService.getProductsSmall().then(data => this.products = data);
    this.globalFilters = [...this.customColumn.map(el => {
      
      return el.field.value1 ? el.field.value1 : el.field 
    } )]
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['columns'] || changes['data'] || changes['title'] || changes['paginator'] || changes['totalRows'] ){
    this.columns = this.columns
      this.data = this.data
      this.customColumn = this.customColumn
      this.frozencols = this.frozencols
      
      // this.columns.filter(map1=> this.frozencols.find((map2:any)=> map1.name !== map2.name))
      this.globalFilters = [...this.customColumn.map(el => {
      
        return el.field.value1 ? el.field.value1 : el.field
      } )]
      this.totalRecords = this.totalRows

      if (this.paginator == false) this.rows = this.totalRows

      if(this.data?.length > 0) {
        this.dataTable = this.data.slice(this.first, (this.first + this.rows))
        
      }else {
        this.dataTable = this.data
      }

      if ( this.textFiltered?.length > 0 ) {
        this.dataTable  =this.dataTable.filter((obj) =>  JSON.stringify(obj).toLowerCase().indexOf(this.textFiltered.toLowerCase()) !== -1);
        // console.log( 'data-table' , this.dataTable)
      }
      
    }
  }

  onLoadData(event : any)
  {
    if(this.data.length > 0) {
      this.dataTable = this.data.slice(event.first, (event.first + event.rows))
      let pageIndex = event.first / event.rows + 1
      this.rows = event.rows
      this.textFiltered = event.globalFilter
      this.onLazyData.emit({ rows : event.rows , pageIndex : pageIndex })

    }
  }
  Getgabunganarray()
  {
    var abcc=[];
    if(this.frozencols != 0)
    {
      abcc = this.customColumn.filter((map1:any)=> this.frozencols.find((map2:any)=> map1.name != map2.name))
    }
    else
    {
      abcc = this.customColumn
    }
    return abcc;
  }
}
