import {Component, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ParamApiModelService, ParamApiService} from 'src/app/shared/services/param-api.service';
import {SidebarService} from 'src/app/shared/services/sidebar.service';
import {ApiService} from 'src/app/shared/services/api.service';
import {BreadcrumService} from 'src/app/shared/services/breadcrum.service';
import {BreadcrumbModel} from 'src/app/models/breadcrumb/breadcrum.model';
import {LoginService} from 'src/app/shared/services/login.service';
import {OrderBooksService} from 'src/app/shared/services/order-book.service';

import * as signalR from "@microsoft/signalr"
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {FormControl} from '@angular/forms';
import {HeaderService} from 'src/app/shared/services/header.service';
import {environment} from 'src/environments/environment';
import {NotificationService} from "../../shared/services/notification.service";
import {shareReplay} from "rxjs";

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [],
})
export class HeaderComponent implements OnInit {

  public value: any = 'value'
  public isOpen: boolean
  public isProfile: boolean = false
  public openSidebarMobile: boolean = false;
  public hideSidebar: boolean = false

  public project: any;
  public multipleProject: any = [];
  public tempMP: any = [];
  public date: any;
  public projectData: any = [];
  public dataBreadcrumb!: BreadcrumbModel

  public userName: string = 'DH';
  public dateNow = new Date();
  public minDate: any = new Date((this.dateNow.getFullYear() - 50), 0, 1);
  public maxDate: any = new Date((this.dateNow.getFullYear() + 50), 0, 1);
  public maxDateNow: any = this.dateNow;
  public date2 = new FormControl(moment());
  public fromDate = new FormControl(moment());
  public endDate = new FormControl(moment());
  public filterId: number = 1;
  public level: Array<any> = [
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
  ]

  public selectedLevel: any = 1

  public listLocation: Array<any> = []
  public selectedLocation: any = null
  public parameters!: ParamApiModelService;

  public isHide: boolean = false;

  private hubConnection!: signalR.HubConnection;
  tooltipTitle: any;
  tooltipContent: any;
  isShow = false;
  pushShow = 'none';
  selectedAllProject: any = [];
  listNotification: any = [];
  countUnread = null;


  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    public paramApiService: ParamApiService,
    private apiService: ApiService,
    private breadcrumbService: BreadcrumService,
    private loginService: LoginService,
    private orderBookService: OrderBooksService,
    private headerService: HeaderService,
    private notificationService: NotificationService,
    private render: Renderer2
  ) {
    this.isOpen = false;
    this.headerService.filterId.subscribe(
      id => {
        this.filterId = id
      }
    )

    this.headerService
      .isHideFilter
      .subscribe(res => {
        this.isHide = res
      })

    this.startConnection();

    this.refreshUnreadNotification();
  }


  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date2.value;
    ctrlValue.year(normalizedYear.year());
    this.date2.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date2.value;
    ctrlValue.month(normalizedMonth.month());
    this.date2.setValue(ctrlValue);
    this.date = new Date(ctrlValue)
    datepicker.close();

    // this.getChange()
    this.getChangeDate()

  }


  ngOnInit(): void {
    this.getNotifCount();
    this.getNotifList();

    this.date = this.date2.value
    let username = localStorage.getItem('_ud')
    if (username) {
      this.loginService.userName.next(username)
    }

    this.breadcrumbService.data.subscribe(
      res => {
        if (res) {
          this.dataBreadcrumb = {
            parentPage: res.parentPage,
            page: res.page
          }

        }
      }
    )

    this.apiService.getProjects()
      .subscribe((res: Array<any>) => {
        this.projectData.push({id: 'ALL', name: 'Konsolidasi'})
        this.projectData.push(...res);
        this.multipleProject[0] = this.paramApiService.multipleProject.value[0];
        this.tempMP = this.multipleProject;
        for (let i = 0; i < res.length; i++) {
          this.selectedAllProject[i] = res[i].id;
        }
        this.paramApiService.idProject.next(this.projectData[0]?.id)
      }, err => {
        console.log(err)
      })

    this.sidebarService.isOpen.subscribe(
      res => {
        if (res != null) {
          this.openSidebarMobile = res
        }
      }
    )

    // loginService
    this.loginService.userName.subscribe(
      res => {
        if (res) {
          this.userName = res
        }
      }
    )

    // location

    this.paramApiService.parametersObs$.subscribe(
      res => {
        this.headerService.getFilterLocation(res.idProject)
          .subscribe((res: Array<any>) => {
              // this.listLocation = [ { nama : 'All Location' , lokasi : null } , ...res ]
              this.listLocation = [{nama: 'All Location', id: null}, ...res]
            },
            err => {
              console.log(err)
            })
      },
    )


    if (localStorage.getItem('breadCrumb')) {
      let data: any = localStorage.getItem('breadCrumb')
      this.dataBreadcrumb = JSON.parse(data)

    }

  }

  toggle() {
    this.isOpen = !this.isOpen
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  toggleSidebarMobile() {
    this.openSidebarMobile = !this.openSidebarMobile
    this.sidebarService.isOpen.next(this.openSidebarMobile)


    this.openSidebarMobile ? this.render.addClass(document.getElementById('body'), 'overflow-hidden') : this.render.removeClass(document.getElementById('body'), 'overflow-hidden')

  }

  toggleSideBar() {
    this.hideSidebar = !this.hideSidebar
    this.sidebarService.sidebarHide.next(this.hideSidebar)
  }

  getChange() {

    if (this.project?.length != 0 && !undefined) {
      if (this.project != undefined) {
        this.paramApiService.idProject.next(this.project)
      }
    }

    if (this.date?.length != 0 && !undefined) {
      if (this.date != undefined) {
        this.date = new Date(this.date2?.value?._d)
        let date = new Date(this.date2?.value?._d)
        let month = date?.getMonth() + 1
        let year = date?.getFullYear()

        // let dateSummary = new Date(this.date?._d)
        // let newDate = new Date(dateSummary.getTime() - (dateSummary.getTimezoneOffset() * 60000))

        this.paramApiService.date.next(date)
        // this.orderBookService.monthTakeUpRate.next(month)
        localStorage.setItem('month', JSON.stringify(month))
        localStorage.setItem('year', JSON.stringify(year))

        // this.paramApiService.parametersObs$.next({
        //   date : date,
        //   idProject : this.project ? this.project : 'ALL'
        // })

      }
    }

  }

  getChangeDate() {
    if (this.project?.length != 0 && !undefined) {
      if (this.project != undefined) {
        this.paramApiService.idProject.next(this.project)
      }
    }

    if (this.date?.length != 0 && !undefined) {
      if (this.date != undefined) {
        this.date = new Date(this.date2?.value?._d)
        let date = new Date(this.date2?.value?._d)
        let month = date?.getMonth() + 1
        this.orderBookService.monthTakeUpRate.next(month)
        this.paramApiService.date.next(date)

        this.paramApiService.parametersObs$.next({
          date: date,
          idProject: this.project ? this.project : 'ALL'
        })


        this.parameters = {
          date: this.date,
          idProject: this.project ? this.project : 'ALL',
          location: this.selectedLocation,
          level: this.selectedLevel
        }

      }
    }
  }

  onSelectProject() {
    console.log(this.multipleProject)
    console.log(this.tempMP)
    if (this.multipleProject?.length != 0 && !undefined) {
      if (this.multipleProject != undefined) {
        if (this.tempMP.indexOf("ALL") == 0 && (this.multipleProject.indexOf("ALL") == 0 && this.multipleProject.length > 1)) {
          console.log("masuk t")
          let index = this.multipleProject.indexOf("ALL");
          if (index !== -1) {
            this.multipleProject.splice(index, 1);
          }
          this.tempMP = this.multipleProject;
        } else if(this.tempMP.indexOf("ALL") == -1 && this.multipleProject.indexOf("ALL") == 0) {
          console.log("masuk f")

          this.multipleProject = [];
          this.multipleProject[0] = "ALL";
          this.tempMP = this.multipleProject;
        }
        this.paramApiService.multipleProject.next(this.multipleProject)
        this.updateState();
      }
    }
  }

  onChangeFromDate() {
    if (this.fromDate?.value != 0 && !undefined) {
      if (this.fromDate?.value != undefined) {
        this.paramApiService.dateFrom.next(this.fromDate?.value.format("YYYY-MM-DD"))
        this.updateState();
      }
    }
  }

  onChangeEndDate() {
    if (this.endDate?.value != 0 && !undefined) {
      if (this.endDate?.value != undefined) {
        this.paramApiService.dateTo.next(this.endDate?.value.format("YYYY-MM-DD"))
        this.updateState();
      }
    }
  }

  updateState() {
    let date = new Date(this.date2?.value?._d)
    this.paramApiService.parametersObs$.next({
      date: date,
      idProject: this.project ? this.project : 'ALL',
      multipleProject: this.multipleProject,
      dateFrom: this.fromDate?.value.format("YYYY-MM-DD"),
      dateTo: this.endDate?.value.format("YYYY-MM-DD")
    })
  }

  startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.API_URL + `notify`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  refreshUnreadNotification() {
    // this.hubConnection.on('BroadcastMessage', (data: any) => {
    //   // @ts-ignore
    //   this.tooltipContent = data;
    //   this.pushShow = 'block';
    //   setTimeout(() => {
    //     this.pushShow = 'none';
    //   }, 3600);
    //   this.updateState();
    //   this.getNotifCount();
    //   this.getNotifList();
    // });

    this.notificationService.getList()
      .pipe(shareReplay(1))
      .subscribe(
        res => {
          for (let i = 0; i < res.length; i++) {
            if (!res[i].isRead && this.tooltipContent == null){
              this.tooltipTitle = res[i].title;
              this.tooltipContent = res[i].message;
              this.pushShow = 'block';
              setTimeout(() => {
                this.pushShow = 'none';
              }, 3600);
            }
          }
        },
        err => {
          console.log(err)
        }
      )
      this.updateState();
      this.getNotifCount();
      this.getNotifList();
  }


  getNotifList() {
    this.notificationService.getList()
      .pipe(shareReplay(1))
      .subscribe(
        res => {
          this.listNotification = res;
          for (let i = 0; i < this.listNotification.length; i++) {
            this.listNotification[i].isExpanded = false;
          }
        },
        err => {
          console.log(err)
        }
      )
  }

  getNotifCount() {
    this.notificationService.getCountUnread()
      .pipe(shareReplay(1))
      .subscribe(
        res => {
          this.countUnread = res;
        },
        err => {
          console.log(err)
        }
      )
  }

  readMessage(id: any, index: any, accordionItem:any) {
    this.listNotification[index].isExpanded = !this.listNotification[index].isExpanded;
    if (!this.listNotification[index].isRead) {
      this.notificationService.updateToRead(id)
        .pipe(shareReplay(1))
        .subscribe(
          res => {
            accordionItem
            this.getNotifCount();
            this.listNotification[index].isRead = !this.listNotification[index].isRead;
          },
          err => {
            console.log(err)
          }
        )
    }

  }

  onMouseLeave() {
    this.isShow = !this.isShow;
    this.getNotifCount();
    this.getNotifList();
  }
}
