import { Component, OnInit , Input, OnChanges, SimpleChanges,ViewEncapsulation, AfterViewInit} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-card-liquidity',
  templateUrl: './card-liquidity.component.html',
  styleUrls: ['./card-liquidity.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class CardLiquidityComponent implements OnInit , OnChanges{

  @Input() data : any;
  @Input() isError! : boolean;
  public thisMonthActive=true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['data']) {

      if(this.data) {

        this.data = {
          cashPositionThisMonthActual : this.data.reduce((curent: any , obj: any) => curent + obj.cashPositionThisMonthActual , 0),
          cashPositionThisMonthPlan : this.data.reduce((curent: any , obj: any) => curent + obj?.cashPositionThisMonthPlan , 0),
          cashPositionYearToDateActual : this.data.reduce((curent: any , obj: any) => curent + obj?.cashPositionYearToDateActual , 0),
          cashPositionYearToDatePlan : this.data.reduce((curent: any , obj: any) => curent + obj?.cashPositionYearToDatePlan , 0),
          netCashFlowThisMonthActual : this.data.reduce((curent: any , obj: any) => curent + obj?.netCashFlowThisMonthActual , 0),
          netCashFlowThisMonthPlan : this.data.reduce((curent: any , obj: any) => curent + obj?.netCashFlowThisMonthPlan , 0),
          netCashFlowYearToDateActual : this.data.reduce((curent: any , obj: any) => curent + obj?.netCashFlowYearToDateActual , 0),
          netCashFlowYearToDatePlan : this.data.reduce((curent: any , obj: any) => curent + obj?.netCashFlowYearToDatePlan , 0),
          cashConversionCycle : this.data.reduce((curent: any , obj: any) => curent + obj?.cashConversionCycle , 0),
        }

      }
      
    }


    if(changes['isError']){
      this.isError = this.isError
    }
  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index==0)
      this.thisMonthActive = true
    else
      this.thisMonthActive = false
  }

  ngOnInit(): void {

  }


}
