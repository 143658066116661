import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() listData : Array<any> = []

  public srcIconV2 : string = 'assets/image/icon_v2/';
  public png : string = '.png';
  constructor() { }

  ngOnInit(): void {
    
  }

}
