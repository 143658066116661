<div class="summary-comp">

    <div class="card">
        <div class="container" >
            <div class="summary-box" *ngFor="let data of listData">
                <div class="summary-content">
                    <div class="summary-icon">
                        <img class="icon" [src]="srcIconV2 + data?.icon + png " alt="">
                    </div>
                    <div class="summary-title">
                        <div class="summary-title-key">{{data.key}}</div>
                        <div class="summary-title-value">{{data.value | number }}</div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
</div>
