import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-feasibility',
  templateUrl: './table-feasibility.component.html',
  styleUrls: ['./table-feasibility.component.scss']
})
export class TableFeasibilityComponent implements OnInit , OnChanges {

  @Input() public data : any
  @Input() public isError! : boolean
  @Input() public columns : any
  @Input() public label : any;
  @Input() public colorText? : string = ''
  @Input() public backgroundHeader? : string = ''

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isError']) {
      this.isError = this.isError
    }
  }

  ngOnInit(): void {
  }

}
