import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';


const routes: Routes = [
  { path : '' , redirectTo : '/login' , pathMatch : 'full' },
  {
    path : 'feasibility' ,
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/feasibility/project-information.module').then(m=> m.ProjectInformationModule)
  },
  {
    path : 'order-book',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/order-book/order-book.module').then(m => m.OrderBookModule)
  },
  {
    path : 'budget',
    canActivate : [AuthGuard],
    loadChildren : ()=> import ('./pages/budget/budget.module').then(m => m.BudgetModule)
  },
  {
    path : 'cut-off-budget',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/cut-off-budget/cut-off-budget.module').then(m => m.CutOffBudgetModule)
  },
  {
    path : 'dashboard',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path : 'homepage',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/homepage/company-overview.module').then(m => m.CompanyOverviewModule)
  },
  {
    path : 'matrix-resume',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/matrix-resume/matrix-resume.module').then(m => m.MatrixResumeModule)
  },
  {
    path : 'profitability',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/profitability/profitability.module').then(m => m.ProfitabilityModule)
  },
  {
    path : 'forgot-password',
    loadChildren : ()=> import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path : 'not-found',
    component : NotFoundComponent
  },
  {
    path : 'login',
    loadChildren : ()=> import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path : 'generate',
    loadChildren : ()=> import ('./pages/generate-page/generate-page.module').then(m => m.GeneratePageModule)
  },
  {
    path : 'cash-in',
    canActivate : [AuthGuard],
    loadChildren : ()=> import('./pages/cash-in/cash-in.module').then(m => m.CashInModule)
  },
];

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
