import { Component, OnInit , ViewChild , OnChanges, SimpleChanges, Input } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  colors: string[] | any;
};


@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit , OnChanges{

  @Input() colors : any[] = []
  @Input() data : any;
  @Input() isError! : boolean;

  @ViewChild("chartArea") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  private months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  private monthFinal : Array<any> = []

  constructor() {
    this.chartOptions = {
      // series: [
      //   {
      //     name: "series1",
      //     data: [ 100, 90, 70]
      //   }
      // ],
      chart: {
        height: 440,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      // colors : ['#BD0001'],
      xaxis: {
        type: "datetime",
        // categories: [
        //   "",
        // ]
      },
      tooltip: {
        x: {
          format: "MM/yy"
        }
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] || changes['colors']){

      if(this.data?.name == 'Liquidity Margin (%)' ){

        this.data = this.data
        let month = this.data?.date
        month.forEach((val : any) => {
          this.monthFinal.push(this.months[val - 1])
        });

        this.chartOptions = {
          ...this.chartOptions,
          series : [this.data],
          colors : this.colors,
          xaxis : {
            categories : this.monthFinal
          }
        }

      }

      if(this.data?.name == 'Net Profit Margin (%)' ) {
        this.data = this.data
        console.log('data' , this.data)

        this.chartOptions = {
          ...this.chartOptions,
          series : [this.data],
          colors : this.colors,
          xaxis : {
            categories : this.data?.date
          }
        }
      }

      
    }

    if(changes['isError']){
      this.isError = this.isError
    }
  }

  ngOnInit(): void {
    
  }

}
