<section
  class="sidebar-comp"
  [ngClass]="{ openMobile: openSidebarMobile, onHide: hideSidebar }"
>
  <div class="user">
    <div class="wrapper-logo">
      <div class="logo">
        <!-- <img src="../../../assets/image/icon/adcp_logo.svg" alt=""> -->
      </div>
      <div class="logo-text">
        <h1>Adhi Commuter Properti</h1>
      </div>
    </div>
  </div>

  <div class="close-sidebar">
    <ul (click)="sidebarService.isOpen.next(false); closeSidebar()">
      <li></li>
      <li></li>
    </ul>
  </div>
  <div class="user-mobile">
    <div class="right flex flex-col justify-end">
      <div class="profile">
        <span class="profile-pict">
          {{ username }}
        </span>
      </div>

      <div class="project flex justify-between items-center">
        <div class="input">
          <mat-form-field>
            <mat-select
              (selectionChange)="getChange()"
              name="countryVaraible"
              [(value)]="idProject"
              placeholder="Project"
            >
              <mat-option *ngFor="let item of projectData" [value]="item?.id">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="calendar flex justify-between items-center">
        <div class="input">
          <mat-form-field>
            <mat-label>Month and Year</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              class="date-input"
              [formControl]="date2"
              [min]="minDate"
              [max]="maxDate"
              (dateChange)="getChangeDate()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, picker)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <ul id="accordion" class="accordion">
    <li *ngFor="let menu of menus; let i = index" [class.active]="menu?.active">
      <a
        class="menu menu-a"
        *ngIf="!menu.isParent"
        (click)="toggle(i); parent(menu)"
        routerLinkActive="active-link"
        (click)="closeSidebar()"
        [routerLink]="['/' + menu?.url]"
      >
        <div class="menu-item">
          <i [class]="menu?.iconClass">
            <img
              *ngIf="menu.active"
              [src]="
                menu?.version == 1
                  ? srcIcon + menu?.imgIcon_active + png
                  : srcIconV2 + menu?.imgIcon_active + png
              "
              alt=""
            />
            <img
              *ngIf="!menu.active"
              [src]="
                menu?.version == 1
                  ? srcIcon + menu?.imgIcon + png
                  : srcIconV2 + menu?.imgIcon + png
              "
              alt=""
            />
          </i>
          <span>
            {{ menu?.name }}
          </span>
        </div>
      </a>
    </li>
  </ul>

  <div class="wrapper-logout">
    <div class="profile-info">
      <span class="logout">
        <span (click)="onLogout()"> Logout </span>
      </span>
    </div>
  </div>
</section>
