<section class="line-chart" *ngIf="colors.length > 0">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->
    <h2>
      {{ 'Take Up Rate' }}
    </h2>

    <div class="filter " *ngIf="isFiltered">

      <mat-form-field>
        <mat-select (selectionChange)="getChange()" 
                    name="countryVaraible" 
                    [(value)]="filterData" >
          <mat-option *ngFor="let item of filter" 
                      [value]="item?.value">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div id="lineChart">
        <apx-chart
          [series]="chartOptions?.series"
          [chart]="chartOptions?.chart"
          [xaxis]="chartOptions?.xaxis"
          [dataLabels]="chartOptions?.dataLabels"
          [grid]="chartOptions?.grid"
          [stroke]="chartOptions?.stroke"
          [title]="chartOptions?.title"
          [colors]="chartOptions?.colors"
        ></apx-chart>
      </div>
</section>
