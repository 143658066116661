<section class="mat-table-comp">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
      
          <!-- Position Column -->
          <ng-container *ngFor="let col of columns" matColumnDef="{{ col?.field }}">
            <th mat-header-cell *matHeaderCellDef> {{ col?.name }} </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element[col.field]?.value || element[col.field]?.percent ; else other">
                    <span class="header">
                        {{ element[col.field]?.value | number }}
                    </span>
                    <br>
                    <span class="sub">
                        {{ element[col.field]?.percent + ' %' }}
                    </span>
                </ng-container>
                <ng-template #other>
                    {{ element[col.field] }}
                </ng-template>
            </td>
          </ng-container>
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                       showFirstLastButtons 
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
</section>
