import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private apiService : ApiService){}

  getList() : Observable<any>
  {
    let url = `Notification`
    return this.apiService.get(url) as Observable<any>
  }
  getListUnread() : Observable<any>
  {
    let url = `Notification/ListUnread`
    return this.apiService.get(url) as Observable<any>
  }
  getCountUnread() : Observable<any>
  {
    let url = `Notification/CountUnread`
    return this.apiService.get(url) as Observable<any>
  }
  updateToRead(id:any) : Observable<any>
  {
    let url = `Notification/${id}`
    return this.apiService.putNoBody(url) as Observable<any>
  }

}
