<div class="card-margin dark:bg-slate-800 " [ngClass]="{ 'shadow-smooth' : shadow }">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->
  <h3 class="title text-center" *ngIf="label2;else other_content" >
    {{ label2 }}
    <br>
    {{ label }}
  </h3>

  <ng-template #other_content>
    <h3 class="title">
      {{ label }}
    </h3>
  </ng-template>


  <section *ngIf="chartOptions?.series && 
                  chartOptions?.chart && 
                  chartOptions?.plotOptions && 
                  chartOptions?.labels ">
  
                  <div id="chart">
                    <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [plotOptions]="chartOptions.plotOptions"
                    [labels]="chartOptions.labels"
                    [legend]="chartOptions.legend"
                    [colors]="chartOptions.colors"
                    [responsive]="chartOptions.responsive"
                    [states]="chartOptions?.states"
                    ></apx-chart>
                </div>
  
  </section>

</div>

