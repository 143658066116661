import { AfterViewInit, Component, OnInit , ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router , NavigationEnd } from '@angular/router';
import { Config } from 'src/app/models/types/config';
import { ParamApiService } from 'src/app/shared/services/param-api.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { BreadcrumService } from 'src/app/shared/services/breadcrum.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { OrderBooksService } from 'src/app/shared/services/order-book.service';
import { HeaderService } from 'src/app/shared/services/header.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [
        MAT_DATE_LOCALE,
        MAT_MOMENT_DATE_ADAPTER_OPTIONS
      ]
    },

    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class SidebarComponent implements OnInit , AfterViewInit {

  public dateNow = new Date();
  public minDate : any = new Date( (this.dateNow.getFullYear() - 50) , 0 , 1);
  public maxDate : any = new Date( (this.dateNow.getFullYear() + 50) , 0 , 1);
  public date2 = new FormControl(moment());
  public username : any;
  public srcIcon : string = 'assets/image/icon/';
  public srcIconV2 : string = 'assets/image/icon_v2/';
  public png : string = '.png';

  config: Config | any;
  options: Config = { multi: false };
  openSidebarMobile : boolean = false
  hideSidebar : boolean = false;

  date : any;
  idProject : any;

  projectData : Array<any> = [];
  menus : any[] = [
    {
      isParent : false,
      name: 'Homepage',
      iconClass: 'home',
      imgIcon_active : 'ic_home_active',
      imgIcon : 'ic_home',
      filterId : 1,
      version : '2',
      active: true,
      url: 'homepage',
      submenu: [
        // { name: 'Homepage', url: 'homepage' , filterId : 1 },
      ]
    },
    {
      isParent : false,
      name: 'Marketing Sales',
      iconClass: 'timeline',
      imgIcon_active : 'ic_chart_active',
      imgIcon : 'ic_chart',
      active: false,
      url : 'dashboard',
      submenu : [],
      filterId : 1,
      version : '2'
    },
    {
      isParent : false,
      name: 'Budget',
      iconClass: 'timeline',
      imgIcon_active : 'ic_wallet_active',
      imgIcon : 'ic_wallet',
      active: false,
      url : 'budget',
      submenu : [] ,
      filterId : 2,
      version : '2'
    },
    {
      isParent : false,
      name: 'Cut Off Budget',
      iconClass: 'timeline',
      imgIcon_active : 'ic_wallet_active',
      imgIcon : 'ic_wallet',
      active: false,
      url : 'cut-off-budget',
      submenu : [] ,
      filterId : 2,
      version : '2'
    },
    {
      isParent : false,
      name: 'Profitability',
      iconClass: 'timeline',
      imgIcon_active : 'ic_chart_active',
      imgIcon : 'ic_chart',
      active: false,
      url : 'profitability',
      submenu : [] ,
      filterId : 1,
      version : '2'
    },
    {
      isParent : false,
      name: 'Feasibility',
      iconClass: 'assignment',
      imgIcon_active : 'icc_feasibility_active',
      imgIcon : 'icc_feasibility',
      active: false,
      filterId : 1,
      version : '2',
      url : 'feasibility',
      submenu: [
        // { name: 'Feasibility', url: 'feasibility' , filterId : 1 },
      ]
    },
    {
      isParent : false,
      name: 'Order Book',
      iconClass: 'timeline',
      imgIcon_active : 'icc_bookmark_active',
      imgIcon : 'icc_bookmark',
      active: false,
      url : 'order-book',
      submenu : [],
      filterId : 1,
      version : '2'
    },
    {
      isParent : false,
      name: 'Matrix Resume',
      iconClass: 'timeline',
      imgIcon_active : 'ic_grapich_active',
      imgIcon : 'ic_grapich',
      active: false,
      url : 'matrix-resume',
      submenu : [],
      filterId : 1,
      version : '2'
    },
    {
      isParent : false,
      name: 'Cash in',
      iconClass: 'timeline',
      imgIcon_active : 'ic_money_in_active',
      imgIcon : 'ic_money_in',
      active: false,
      url : 'cash-in',
      submenu : [],
      filterId : 3,
      version : '1'
    },



  ];



  constructor(
      private router : Router ,
      private route : ActivatedRoute,
      private paramApiService : ParamApiService,
      private cdr : ChangeDetectorRef ,
      private apiService : ApiService,
      private breadcrumService : BreadcrumService,
      private orderBookService : OrderBooksService,
      private headerService : HeaderService,
      public sidebarService : SidebarService,
      private render : Renderer2
    ) { }

  ngOnInit(): void {

    // this.route.fragment.subscribe(
    //   val => {
    //     console.log(val)
    //   }
    // )

    this.router.events.subscribe(
      ev => {
        if(ev instanceof NavigationEnd) {
          let url_active = ev.url.toString()



          url_active = url_active.replace('/' , '')

          let index = this.menus.findIndex(menu => menu.url == url_active)
          this.menus.filter(
              (menu , i) => i !== index && menu.active
            ).forEach(menu => menu.active = !menu.active)

            if(url_active !== 'generate') {
              this.menus[index].active = true
            }

            if(url_active == 'generate') {
              this.breadcrumService.data.next({parentPage : 'Generate' , page : 'Generate' })
              this.headerService.isHideFilter.next(true)
            }else {
              this.headerService.isHideFilter.next(false)
            }

            if(url_active == 'cut-off-budget' || url_active == 'budget') {
              this.headerService.filterId.next(2)
            }else if(url_active == 'cash-in') {
              this.headerService.filterId.next(3)
            }else {
              this.headerService.filterId.next(1)
            }
        }
      }
    )

    this.username = localStorage.getItem('_ud')
    this.config = this.mergeConfig(this.options);
    this.sidebarService.isOpen.subscribe(
      res => {
        if(res !=null) {
          this.openSidebarMobile = res
        }
      }
    )

    this.sidebarService.sidebarHide.subscribe(
      res => {
        this.hideSidebar = res
      }
    )


    this.apiService.getProjects()
        .subscribe(
          res => {
            // this.projectData = res
            this.projectData.push({id : 'All' , name : 'Konsolidasi'})
            this.projectData.push(...res);
          },
          err => {
            console.log(err)
          }
        )

        // if(localStorage.getItem('indexMenu')){
        //   let indexMenu = localStorage.getItem('indexMenu')
        //   let index = Number(localStorage.getItem('indexMenu'))
        //   if (!this.config.multi) {
        //     this.menus.filter(
        //       (menu, i) => i.toString() !== indexMenu && menu.active
        //     ).forEach(menu => menu.active = !menu.active);
        //   }

        //   this.menus[index].active = true;
        // }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date2.value;
    ctrlValue.year(normalizedYear.year());
    this.date2.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date2.value;
    ctrlValue.month(normalizedMonth.month());
    this.date2.setValue(ctrlValue);
    this.date = new Date(ctrlValue)
    datepicker.close();

    this.getChange()
    this.getChangeDate()

  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges()
  }



  mergeConfig(options: Config) {

    const config = {
      // selector: '#accordion',
      multi: true
    };

    return { ...config, ...options };
  }

  toggle(index: number) {

    if (!this.config.multi) {
      this.menus.filter(
        (menu, i) => i !== index && menu.active
      ).forEach(menu => menu.active = !menu.active);
    }

    let indexMenu = index.toString()
    localStorage.setItem('indexMenu' , indexMenu)
    // this.menus[index].active = !this.menus[index].active;
    this.menus[index].active = true;
    this.headerService.filterId.next(this.menus[index].filterId)
  }

  closeSidebar()
  {
    this.sidebarService.isOpen.next(false)
    this.render.removeClass(document.getElementById('body') , 'overflow-hidden')
  }

  // onChanges()
  // {
  //   if(this.idProject?.length != 0 && !undefined){
  //     this.sidebarService.isOpen.next(false)
  //     if(this.idProject != undefined){
  //       this.paramApiService.idProject.next(this.idProject)
  //     }
  //   }

  //   if(this.date?.length !=0 && !undefined ){
  //     if(this.date != undefined) {
  //       this.sidebarService.isOpen.next(false)
  //       let dateSummary = new Date(this.date)
  //       let newDate = new Date(dateSummary.getTime() - (dateSummary.getTimezoneOffset() * 60000))
  //       this.paramApiService.date.next(newDate)
  //     }
  //   }
  // }

  getChange()
  {

    if(this.idProject?.length != 0 && !undefined){
      if(this.idProject != undefined) {
        this.paramApiService.idProject.next(this.idProject)
      }
    }

    if(this.date?.length !=0 && !undefined ){
      if(this.date != undefined){
        this.date = new Date(this.date2?.value?._d)
        let date = new Date(this.date2?.value?._d)
        let month = date?.getMonth() + 1
        let year = date?.getFullYear()

        this.paramApiService.date.next(date)
        // this.orderBookService.monthTakeUpRate.next(month)
        localStorage.setItem('month' ,  JSON.stringify(month) )
        localStorage.setItem('year' ,  JSON.stringify(year) )
        this.sidebarService.isOpen.next(false)
      }
    }

  }

  getChangeDate()
  {
    if(this.date?.length !=0 && !undefined ){
      if(this.date != undefined){
        this.date = new Date(this.date2?.value?._d)
        let date = new Date(this.date2?.value?._d)
        let month = date?.getMonth() + 1
        this.orderBookService.monthTakeUpRate.next(month)
        this.sidebarService.isOpen.next(false)
      }
    }
  }

  parent(menu: any)
  {

    if(menu.submenu.length == 0) {
      this.breadcrumService.data.next({parentPage : menu.name , page : menu.name })
      localStorage.setItem('breadCrumb' , JSON.stringify({parentPage : menu.name , page : menu.name }) )
    }
  }

  child(parent : any , child : any)
  {

    this.breadcrumService.data.next({parentPage : parent?.name , page : child?.name})
    localStorage.setItem('breadCrumb' , JSON.stringify({parentPage : parent?.name , page : child?.name}) )
  }

  onLogout()
  {
    localStorage.clear()
    this.sidebarService.isOpen.next(false)
    this.router.navigate(['/'])
  }




}
