<section class="chart-area" *ngIf="colors.length > 0">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->
  <!-- <h3 *ngIf="data?.name == 'Net Provit Margin (%)' " class="ml-4">
    Nett Provit Margin (%)
  </h3> -->
    <div id="chartArea">
        <apx-chart
          [series]="chartOptions?.series"
          [chart]="chartOptions?.chart"
          [xaxis]="chartOptions?.xaxis"
          [stroke]="chartOptions?.stroke"
          [tooltip]="chartOptions?.tooltip"
          [dataLabels]="chartOptions?.dataLabels"
          [colors]="chartOptions?.colors"
        ></apx-chart>
    </div>

</section>
