<section class="chart-column shadow-smooth" *ngIf="color.length > 0">
  <!-- <app-error-load *ngIf="isError"></app-error-load> -->

  <h2 class="subtitle pl-6 pt-6">
    {{ data?.label }}
  </h2>

    <div class="filter pl-6" *ngIf="isFiltered">

      <mat-form-field>
        <mat-select (selectionChange)="getChange()" 
                    name="countryVaraible" 
                    [(value)]="filterData" >
          <mat-option *ngFor="let item of filter" 
                      [value]="item?.value">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="burning-filter flex flex-row items-center pl-6" *ngIf="data?.label == 'Burning Rate' ">
      <div class="subhead flex flex-row items-center mr-4">
        <span class="title mr-2"> Carry Over : </span>
        <!-- <span class="percent"> {{ (data?.data?.carryOverTotalRevenue / data?.data?.carryOverTotalContract) }} % </span> -->
        <span class="percent"> {{ data?.data?.carryOver }} % </span>
      </div>
      <div class="subhead flex flex-row items-center">
        <span class="title mr-2"> New : </span>
        <!-- <span class="percent"> {{ (data?.data?.burningRatesNewTotalRevenue / data?.data?.burningRatesNewTotalContract) }} % </span> -->
        <span class="percent"> {{ data?.data?.burningRatesNew }} % </span>
      </div>
    </div>

    
    <div id="chartColumns">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [responsive]="chartOptions.responsive"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions?.yaxis"
          [legend]="chartOptions?.legend"
          [fill]="chartOptions?.fill"
          [colors]="chartOptions?.colors"
        ></apx-chart>
      </div>
</section>
