import { Component, OnInit , Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit , OnChanges{

  @Input() close : string = ''

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['close']){
      this.close = this.close
    }
  }

  ngOnInit(): void {
  }

}
