<section class="card-table-long shadow-smooth">
    <!-- <div *ngIf="backgroundHeader !== 'none' || 'transparent' " 
          class="absolute" 
          [ngStyle]="{'background-color' : backgroundHeader }">
  
    </div> -->
      <table class="table-fixed" *ngIf="columns?.columns?.length > 0">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let col of columns?.columns"
                  class="center title" 
                  [ngStyle]="{'color' : colorText }">
                  {{ col?.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <!-- for label -->
              <th>
                {{ columns?.label }}
              </th>
              <!-- <ng-container *ngFor="let item of data">
                <th *ngFor="let col of columns?.columns">
                  {{ item[col?.field] }}
                </th>
              </ng-container> -->

              <ng-container *ngFor="let col of columns?.columns">
                <th>
                  {{ data[col.field] | number : '1.2-2' }}
                </th>
              </ng-container>
            </tr>
          </tbody>
        </table>
  </section>
  
