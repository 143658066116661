<div class="dialog-tooltip">

    <div class="header-tooltip">
        <table>
            <thead>
                <tr>
                    <th>
                        {{ data?.parent?.label }}
                    </th>
                    <th>
                        {{ data?.parent?.data | number }}
                    </th>
                </tr>
            </thead>
        </table>
    </div>

    <div class="content-tooltip">
        <table>
            <tbody>
                <tr *ngFor="let item of data.child.data">
                    <td>
                        {{ item?.label }}
                    </td>
                    <td>
                        {{ item?.total | number }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <!-- <table>
        <thead>
            <tr>
                <th>
                    {{ data?.parent?.label }}
                </th>
                <th>
                    {{ data?.parent?.data }}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of data.child.data">
                <td>
                    {{ item?.label }}
                </td>
                <td>
                    {{ item?.total }}
                </td>
            </tr>
        </tbody>
    </table> -->
</div>
