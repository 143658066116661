import { Injectable , Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent , HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class BearerInterceptor implements HttpInterceptor {

  constructor( @Inject(PLATFORM_ID) private platformId : any , private route : ActivatedRoute ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if(isPlatformBrowser(this.platformId)){

      let token = localStorage.getItem('token');
    
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });

      }

      request = request.clone({
        setHeaders: {
          Accept: 'application/json'
        }
      });

      return next.handle(request);

    // }

  }
}