import { Component, EventEmitter, Input, OnChanges, OnInit , Output, SimpleChanges, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexTooltip,
  ApexGrid,
  ApexYAxis
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip : ApexTooltip;
  yaxis: ApexYAxis;
  colors : string[] | any;
};

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit , OnChanges{
  @Input() data : any;
  @Input() isError! : boolean;
  @Input() colors : Array<any> = []
  @Input() isFiltered : boolean = false ;

  @Output() getFiltered = new EventEmitter()
  @ViewChild("lineChart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  public label : string = '';
  public filterData : any;
  public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  public filter : Array<any> = [
    { name : 'Rupiah' , value : 'rp' },
    { name : 'Unit' , value : 'unit' },
  ]

  constructor(private currency : CurrencyPipe) {

    this.filterData = this.filter[1].value

    this.chartOptions = {
      // series: [
      //   {
      //     name: "Desktops",
      //     data: [10, 20, 35, 51, 70, 90, 69, 50, 30]
      //   },
      //   {
      //     name: "Desktops",
      //     data: [5, 50, 80, 95, 75, 10, 30, 30, 20]
      //   }
      // ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      // dataLabels: {
      //   enabled: false
      // },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val: any, opts: any) {
            return  `Rp ${numberWithCommas(val)}` 
        },
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "",
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      tooltip : {
        x: {
          format: "dd/MM/yy"
        }
      }
      // xaxis: {
      //   categories: []
      // }

    };

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['data'] ){
      
      this.isError = this.isError
      this.chartOptions = {
        ...this.chartOptions,
        colors : this.colors,
        series : this.data?.series,
        xaxis : this.data?.xaxis,
       
      }

      if(this.filterData == 'unit'){
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels : {
            enabled: true,
            enabledOnSeries: undefined,
            formatter : function (val: any, opts: any) {
              return  `${numberWithCommas(val)}`
            }
          }
         
        }
      }

      this.label = this.data?.label
    }

    if(changes['isFiltered']){
      this.isFiltered = this.isFiltered
    }
  }

  ngOnInit(): void {
    this.filterData = this.filter[1].value
  }

  getChange()
  {
    if(this.isFiltered){

      if(this.filterData == 'unit'){
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels : {
            enabled: true,
            enabledOnSeries: undefined,
            formatter : function (val: any, opts: any) {
              return  `${numberWithCommas(val)}`
            }
          }
         
        }
      }else {
        this.chartOptions = {
          ...this.chartOptions,
          dataLabels : {
            enabled: true,
            enabledOnSeries: undefined,
            formatter : function (val: any, opts: any) {
              return  `Rp ${numberWithCommas(val)}`
            }
          }
         
        }
      }

      this.getFiltered.emit(this.filterData)
    }
  }

 

}

function numberWithCommas(val: any) {
  return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

