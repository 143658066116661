import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTableComponent } from './card-table/card-table.component';
import { CardMarginComponent } from './card-margin/card-margin.component';
import { ProfitabilityComponent } from './profitability/profitability.component';
import { SolvencyComponent } from './solvency/solvency.component';
import { GlobalTableComponent } from './global-table/global-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { ChartColumnComponent } from './chart-column/chart-column.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { CardTableLongComponent } from './card-table-long/card-table-long.component';
import { CardLiquidityComponent } from './card-liquidity/card-liquidity.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { OrderBookTableComponent } from './order-book-table/order-book-table.component';
import { TableFeasibilityComponent } from './table-feasibility/table-feasibility.component';
import { LoadingComponent } from './loading/loading.component';
import { ErrorLoadComponent } from './error-load/error-load.component';
import { CurrencyPipe } from '@angular/common';
import { MatTableComponent } from './mat-table/mat-table.component';
import { PrimeTableComponent } from './prime-table/prime-table.component';
import { CardFeasibilityComponent } from './card-feasibility/card-feasibility.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SummaryComponent } from './summary/summary.component';


import { TableModule } from 'primeng/table';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialModule } from '../shared';
import { CdkTableModule } from '@angular/cdk/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { FilterLocationLevelComponent } from './filter-location-level/filter-location-level.component';
import { DialogTooltipComponent } from './dialog-tooltip/dialog-tooltip.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';


@NgModule({
  declarations: [
    CardTableComponent,
    CardMarginComponent,
    ProfitabilityComponent,
    SolvencyComponent,
    GlobalTableComponent,
    ChartColumnComponent,
    LineChartComponent,
    CardTableLongComponent,
    CardLiquidityComponent,
    AreaChartComponent,
    BreadCrumbComponent,
    OrderBookTableComponent,
    TableFeasibilityComponent,
    LoadingComponent,
    ErrorLoadComponent,
    MatTableComponent,
    PrimeTableComponent,
    CardFeasibilityComponent,
    ProgressBarComponent,
    SummaryComponent,
    CardFeasibilityComponent,
    FilterLocationLevelComponent,
    DialogTooltipComponent,
    DialogErrorComponent
  ],
  exports : [
    CardTableComponent,
    CardMarginComponent,
    ProfitabilityComponent,
    SolvencyComponent,
    GlobalTableComponent,
    ChartColumnComponent,
    LineChartComponent,
    CardTableLongComponent,
    CardLiquidityComponent,
    AreaChartComponent,
    BreadCrumbComponent,
    OrderBookTableComponent,
    TableFeasibilityComponent,
    LoadingComponent,
    ErrorLoadComponent,
    MatTableComponent,
    PrimeTableComponent,
    CardFeasibilityComponent,
    ProgressBarComponent,
    SummaryComponent,
    CardFeasibilityComponent,
    FilterLocationLevelComponent,
    DialogErrorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    MaterialModule,
    CdkTableModule,
    TableModule,
    ProgressBarModule
  ],
  providers : [CurrencyPipe]
})
export class GlobalComponentModule { }
