import { Component, OnInit , Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreadcrumbModel } from 'src/app/models/breadcrumb/breadcrum.model';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit , OnChanges{
  @Input()
  data!: BreadcrumbModel;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']) {
      this.data = {
        parentPage : this.data?.parentPage ,
        page : this.data?.page
      }
    }
  }

  ngOnInit(): void {
  }

}
