import { Component, Input, OnInit , OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-order-book-table',
  templateUrl: './order-book-table.component.html',
  styleUrls: ['./order-book-table.component.scss']
})
export class OrderBookTableComponent implements OnInit , OnChanges {

  @Input() public columns : any;
  @Input() public data : any;
  @Input() public isError! : boolean;
  @Input() public colorText : string = ''
  @Input() public backgroundHeader : string = ''

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']){
      this.data = this.data;
      this.columns = this.data?.columns
    }

    if(changes['isError']) {
      this.isError = this.isError
    }
  }

  ngOnInit(): void {
    
  }

}
