import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Api2Service } from './api2.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  filterId : BehaviorSubject<number> = new BehaviorSubject<number>(1)
  isHideFilter : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(private api2 : Api2Service) { }


  getFilterLocation(projectID : any) : Observable<any>
  {
    let url = `Locations?projectID=${projectID}`
    return this.api2.get(url) as Observable<any>
  }
}
