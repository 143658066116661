import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree , Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService : LoginService , private router : Router){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // this.loginService.isLogin.subscribe(
      //   res => {
      //     // return res ? true : this.router.navigate(['/'])
      //     let dataLogin = localStorage.getItem('isLogin')
      //     if(res || dataLogin == 'true' ) {
      //       return 
      //     }else {
      //       this.router.navigate(['/'])
      //     }
      //   }
      // )
      
      // return this.loginService.isLogin.value
      return true
      
  }
  
}
