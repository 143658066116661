import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from '../shared';
// import { FormsModule } from '@angular/forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GlobalComponentModule } from '../global-component/global-component.module';
import {AppModule} from "../app.module";
import {MonthYearDatePickerFormatDirective} from "../shared/directive/month-year-date-picker-format.directive";
import {FullDatePickerFormatDirective} from "../shared/directive/full-date-picker-format.directive";
import {OverlayModule} from "@angular/cdk/overlay";
import {CdkAccordionModule} from "@angular/cdk/accordion";



@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    MonthYearDatePickerFormatDirective,
    FullDatePickerFormatDirective
  ],
  exports : [
    SidebarComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalComponentModule,
    RouterModule,
    OverlayModule,
    CdkAccordionModule,
  ]
})
export class LayoutsModule { }
