import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-table-long',
  templateUrl: './card-table-long.component.html',
  styleUrls: ['./card-table-long.component.scss']
})
export class CardTableLongComponent implements OnInit , OnChanges {
  @Input() public data : any
  @Input() public columns : any
  @Input() public colorText? : string = ''
  @Input() public backgroundHeader? : string = ''

  constructor() { }

  ngOnInit(): void {
    // console.log('columns' , this.columns)
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['columns'] && changes['data']){
      this.columns = this.columns
      this.data = this.data
    }
  }


}
