<section class="location-level">
     <!-- location -->
     <div class="level">

        <mat-form-field>
            <mat-select (selectionChange)="paramApiService.location.next(selectedLocation)" 
                        name="countryVaraible" 
                        [(value)]="selectedLocation" 
                        placeholder="Location">
              <mat-option *ngFor="let item of listLocation" 
                          [value]="item?.lokasi">
                {{item?.nama}}
              </mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <!-- level -->
    <div class="level">

        <mat-form-field>
            <mat-select (selectionChange)="paramApiService.level.next(selectedLevel)" 
                        name="countryVaraible" 
                        [(value)]="selectedLevel" 
                        placeholder="Level">
              <mat-option *ngFor="let item of level" 
                          [value]="item?.value">
                {{item?.label}}
              </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
</section>
