<div class="app" id="app">
  <!-- side bar here -->
  
  <app-sidebar *ngIf="isShowed"></app-sidebar>
  <div class="app-wrapper" [ngClass]="{ 'showed' : !isShowed , 'onHide' : isHideSidebar }">
    <app-header *ngIf="isShowed"></app-header>
      <router-outlet></router-outlet>
    <app-footer *ngIf="isShowed"></app-footer>
  </div>
  
  
</div>