import { Injectable } from '@angular/core';
import { Observable , BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})

export class OrderBooksService {

    public monthTakeUpRate : BehaviorSubject<any> = new BehaviorSubject<any>('0')
    
    constructor(private apiService : ApiService){}

    getSummary(projectID : any , year : any , month : any) : Observable<any>
    {
        let url = `OrderBookSummaries/Project/${projectID}/Year/${year}/Month/${month}`
        return this.apiService.get(url) as Observable<any>
    }

    getOrderBookCharts(param : any) : Observable<any>
    {
        return this.apiService.get(`OrderBooks/Project/${param.idProject}/Year/${param.year}/Month/${param.month}?IsUnit=${param.param}`) as Observable<any>
    }

    getBurningRatesCharts(param : any) : Observable<any>
    {
        return this.apiService.get(`BurningRates/Project/${param.idProject}/Year/${param.year}/Month/${param.month}?IsUnit=${param.param}`) as Observable<any>
    }

    getTakeupRateCharts( param : any , projectID : any , year : any , month : any) : Observable<any>
    {
        return this.apiService.get(`TakeUpRates/Param/${param}/Project/${projectID}/Year/${year}/Month/${month}`) as Observable<any>
    }

}