<section class="order-book-table shadow-smooth">

      <div class="bg1"></div>
      <div class="bg2"></div>
      

      <div class="title">
        <h2> {{ columns?.label }} </h2>
      </div>

      <table class="table-fixed">
          <thead>
            <!-- <tr>
              <th colspan="3" 
                  class="center title" 
                  [ngStyle]="{'color' : colorText }">
                  {{ columns?.label }}
              </th>
            </tr> -->
  
            <tr class="subheader">
                <th></th>
                <th *ngFor="let col of columns?.data"> {{ col?.label }} </th>
            </tr>
  
          </thead>
          <tbody>
            <tr *ngFor="let item of data?.data">
              <th>{{ item?.label }}</th>
              <ng-container *ngFor="let col of columns?.data">
                  <td> {{  col?.field == 'percent' ? (item[col?.field] + '%') : item[col?.field] | number : '1.2-2' }} </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
  
  </section>
  